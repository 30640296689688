import { useState } from "react";
import { Dialog } from "./Dialog";
import { TextField } from "./TextField";
import { MagicIcon, OpenAIIcon } from "./icons";

import "./MagicSettings.scss";
import { FilledButton } from "./FilledButton";
import { CheckboxItem } from "./CheckboxItem";
import { KEYS } from "../keys";
import { useUIAppState } from "../context/ui-appState";

const InlineButton = ({ icon }: { icon: JSX.Element }) => {
  return (
    <span
      style={{
        width: "1em",
        margin: "0 0.5ex 0 0.5ex",
        display: "inline-block",
        lineHeight: 0,
        verticalAlign: "middle",
      }}
    >
      {icon}
    </span>
  );
};

export const MagicSettings = (props: {
  openAIKey: string | null;
  isPersisted: boolean;
  onChange: (key: string, shouldPersist: boolean) => void;
  onConfirm: (key: string, shouldPersist: boolean) => void;
  onClose: () => void;
}) => {
  const { theme } = useUIAppState();
  const [keyInputValue, setKeyInputValue] = useState(props.openAIKey || "");
  const [shouldPersist, setShouldPersist] = useState<boolean>(
    props.isPersisted,
  );

  const onConfirm = () => {
    props.onConfirm(keyInputValue.trim(), shouldPersist);
  };

  return (
    <Dialog
      onCloseRequest={() => {
        props.onClose();
        props.onConfirm(keyInputValue.trim(), shouldPersist);
      }}
      title={
        <div style={{ display: "flex" }}>
          线框转代码 (AI){" "}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "0.1rem 0.5rem",
              marginLeft: "1rem",
              fontSize: 14,
              borderRadius: "12px",
              background: theme === "light" ? "#FFCCCC" : "#703333",
            }}
          >
            实验性
          </div>
        </div>
      }
      className="MagicSettings"
      autofocus={false}
    >
      <p
        style={{
          display: "inline-flex",
          alignItems: "center",
          marginBottom: 0,
        }}
      >
        线框转代码使用的是{" "}
        <InlineButton icon={OpenAIIcon} />
        OpenAI. (gpt-4-1106-vision-preview)
      </p>
      <p></p>
      <TextField
        isPassword
        value={keyInputValue}
        placeholder="请输入您的OpenAI 密钥"
        label="API key"
        onChange={(value) => {
          setKeyInputValue(value);
          props.onChange(value.trim(), shouldPersist);
        }}
        selectOnRender
        onKeyDown={(event) => event.key === KEYS.ENTER && onConfirm()}
      />
      <p style={{ marginTop: '44px' }}>
        默认情况下，您的 API 令牌不会保留在任何地方，因此您需要在重新加载后再次输入。但是，您可以在下面的浏览器中本地保存。
      </p>

      <CheckboxItem checked={shouldPersist} onChange={setShouldPersist}>
        本地存储
      </CheckboxItem>

      <p style={{ marginTop: '22px' }}>
        设置 API 密钥后，您可以使用 <InlineButton icon={MagicIcon} />{" "}
        工具将您的元素包装在框架中，然后您可以将其转换为代码。
      </p>

      <FilledButton
        className="MagicSettings__confirm"
        size="large"
        label="确定"
        onClick={onConfirm}
      />
    </Dialog>
  );
};
